module controllers {
    export module tariff {
        interface IProductTariffUpdateScope extends ng.IScope {
        }

        interface ISalesUpdateParams extends ng.ui.IStateParamsService {
            tprId: number;
        }

        export class productTariffUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q', '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'tariffPriorityService',
                'productTypeService',
                'classificationValueService',
                'tariffCountryService',
                'productTariffService',
                'countryService',
                'statisticalUnitService',
                "tariffCountryGroupService",
                "tariffCountryGroupOriginService",
                "tariffPreferenceCodeService",
                'documentRepositoryService',
                'uNDGCodeService', 'customAttributeService', 'customAttributeAnswerOptionService',
                'lookupService',
                'productService',
                'hsCodeService',
                'permitService',
                "prohibitedAndRestrictedService",
                "menuService",
                'wcoTradeToolsService',
                "additionalInfoService"
            ];

            productImage: interfaces.master.IProductImage;
            tprId: number;
            public productTariff: interfaces.tariff.IProductTariff;
            breadCrumbDesc: string;

            //consignment Document Grid
            apiTariffDetailList: uiGrid.IGridApi;

            //UoM Grid
            apiUoM: uiGrid.IGridApi;

            //CountryGroup Grid
            apiCG: uiGrid.IGridApi;

            //Attribute Grid
            apiPA: uiGrid.IGridApi;

            //Additional Info Grid
            apiProductAdditionalInfo: uiGrid.IGridApi;  

            //Restrictions Grid
            apiRestrictions: uiGrid.IGridApi;

            //Permits Grid
            apiPermits: uiGrid.IGridApi;

            simulationDate: moment.Moment = moment.utc();

            statusList: interfaces.applicationcore.IDropdownModel[] = [];
            packingGroupList: interfaces.applicationcore.IDropdownModel[] = [];
            attributeList: interfaces.applicationcore.IDropdownModel[] = []; 
            attributeAnswerOptionList: interfaces.applicationcore.IDropdownModel[] = []; 

            additionalInfoList: interfaces.applicationcore.IDropdownModel[] = [];   
            additionalInfoOptionList: interfaces.applicationcore.IDropdownModel[] = [];

            isLoadingDetail: boolean = false;
            showAll: boolean = false;

            showAllPermits: boolean = false;

            showAllDoc: boolean = false;

            selectedTab: number = 0; 

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            IsDisabled: boolean = false;

            hasProductMasterRights: boolean = false;
            wcoTradeToolsEnabled: boolean = false;

            constructor(
                private $scope: IProductTariffUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: ISalesUpdateParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public tariffPriorityService: services.master.tariffPriorityService,
                public productTypeService: services.master.productTypeService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public tariffCountryService: interfaces.tariff.ITariffCountryService,
                public productTariffService: services.tariff.productTariffService,
                public countryService: interfaces.applicationcore.ICountryService,
                public statisticalUnitService: interfaces.tariff.IStatisticalUnitService,
                public tariffCountryGroupService: interfaces.tariff.ITariffCountryService,
                public tariffCountryGroupOriginService: interfaces.tariff.ITariffCountryGroupOriginService,
                public tariffPreferenceCodeService: interfaces.tariff.ITariffPreferenceCodeService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public UNDGCodeService: services.applicationcore.uNDGCodeService,
                public customAttributeService: services.master.customAttributeService,
                public customAttributeAnswerOptionService: services.master.customAttributeAnswerOptionService,
                public lookupService: interfaces.applicationcore.ILookupService,
                public productService: interfaces.master.IProductService,
                public hsCodeService: interfaces.tariff.IHsCodeService,
                private permitService: interfaces.master.IPermitService,
                private prohibitedAndRestrictedService: interfaces.tariff.IProhibitedAndRestrictedService,
                private menuService: interfaces.applicationcore.IMenuService,
                private wcoTradeToolsService: interfaces.tariff.IWCOTradeToolsService,
                private additionalInfoService: interfaces.tariff.IAdditionalInfoService,
                
            ) {

                this.tprId = $stateParams.tprId;    

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadTariffProduct(this.tprId));
                loadPromises.push(this.getHasProductMasterRights());
                loadPromises.push(this.verifyWCOTradeToolsEnabled());
                

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });

            }

            verifyWCOTradeToolsEnabled()
            {
                return this.wcoTradeToolsService.VerifyWCOTradeToolsEnabled().get({ }, (result: interfaces.tariff.VerifyTradeTools) => {
                    this.wcoTradeToolsEnabled = result.isWCOTradeToolsEnabled;
                }).$promise;     
            }

            loadTariffProduct(Id : number) {

                return this.productTariffService.GetTariffProduct().query({ Id: Id }, (data: interfaces.tariff.IProductTariff) => {

                    this.productTariff = data;
                    this.breadCrumbDesc = this.productTariff.Code + " - " + this.productTariff.ShortDescription;

                    this.isLoadingDetail = true;

                    if (this.productTariff.imageId > 0) {
                        this.loadProductImage(this.productTariff.imageId);
                    }

                    this.$timeout(() => {
                        this.gvwTariffDetail.data = this.productTariff.ProductTariffDetailList;
                        this.gvwUoM.data = this.productTariff.ProductUnitOfMeasureList;
                        this.gvwCountryGroup.data = this.productTariff.ProductCountryGroupList;
                        this.gvwProductAttribute.data = this.productTariff.ProductAttributeList;
                        this.gvwRestrictions.data = this.productTariff.ProductRestrictionDetailList;
                        this.gvwPermits.data = this.productTariff.ProductPermitDetailList;
                        this.gvwProductAdditionalInfo.data = this.productTariff.ProductAdditionalInfoList;
                        
                        this.isLoadingDetail = false;
                    });

                    this.getDocuments(this.showAllDoc);

                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;

            }

            getHasProductMasterRights() {
                this.hasProductMasterRights = false;
                return this.menuService.getGTSConnectMenuItem(80).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.hasProductMasterRights = true;
                    }
                }).$promise;
            }


            loadProductImage(imageId : number) {
                var loadPromises: ng.IPromise<any>[] = [];
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.update'
                },
                    () => {
                        loadPromises.push(this.getProductImage(imageId));                    
                        return this.$q.all(loadPromises);
                    });
            }

            getProductImage(imageId: number) {
                return this.productService.getProductImage(imageId).query({}, (productImage: interfaces.master.IProductImage) => {
                   
                    this.productImage = productImage;

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }


            getDocuments(showAll:boolean) {
                return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.productTariff.Code, systemType: Enum.EnumSystemType.ProductTariffing, ownerEntityId: this.productTariff.OwnerEntityId , showAll:showAll}, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    this.documentRepository = documentRepository;

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            refreshDocuments(showAll:boolean) {
                this.documentRepository = null;
                this.showAllDoc = showAll;
                return this.getDocuments(showAll);
            }

            printDocuments() {

                this.$state.go("auth.ProductTariff.Update.Documents", { tprId: this.productTariff.Id });
            }

            ChangeTariffPriority() {
                var tariffPriorityId: number = 0; //To allow user to blank priority
                if (this.productTariff.TariffPriority && this.productTariff.TariffPriority.Id)
                    tariffPriorityId = this.productTariff.TariffPriority.Id

                return this.productTariffService.ChangePriority(this.productTariff.Id, tariffPriorityId ).save(
                    {  }, this.productTariff.TariffPriorityDate,
                    (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data);
                        //this.loadTariffPriority();
                        this.loadTariffProduct(this.$stateParams.tprId);
                    }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            tariffPrioritySubmit_Click() {
                this.generalService.displayConfirmationBox("Confirm?", "Change  Tariff Priority?").then((result: boolean) => {
                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'pt.update'
                        },
                            () => {
                                return this.ChangeTariffPriority();
                            });
                    }
                });
            }

            tariffCommentsSubscribe_Click() {
                this.generalService.displayConfirmationBox("Confirm?", "Subscribe to Tariff Comments Added event?").then((result: boolean) => {
                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'pt.update'
                        },
                            () => {
                                return this.tariffCommentsSubscribe();
                            });
                    }
                });
            }

            tariffCommentsSubscribe()
            {
                return this.productTariffService.SubscribeToTariffingCommentsAddedEvent().save(
                    {},
                    (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data);
                    }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            tariffPrioritySubscribe() {
                return this.productTariffService.SubscribeToTariffingPriorityChangedEvent().save(
                    {}, 
                    (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data);
                    }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            tariffPrioritySubscribe_Click() {
                this.generalService.displayConfirmationBox("Confirm?", "Subscribe to Tariff Priority changed event?").then((result: boolean) => {
                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'pt.update'
                        },
                            () => {
                                return this.tariffPrioritySubscribe();
                            });
                    }
                });
            }

            tariffChangeTariffComments_Click() {
                this.generalService.displayConfirmationBox("Confirm?", "Change  Tariff Comments?").then((result: boolean) => {
                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'pt.update'
                        },
                            () => {
                                return this.ChangeTariffComments();
                            });
                    }
                });
            }

            ChangeTariffComments() {
                return this.productTariffService.ChangeTariffComments(
                    this.productTariff.Id,
                    this.productTariff.SupplierTariffCode,
                    this.productTariff.OriginalTariffComments,
                    this.productTariff.TariffComments,
                    this.productTariff.AdditionalDescription).save(
                    {}, this.productTariff.TariffPriorityDate,
                    (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data);
                        //this.loadTariffPriority();
                        this.loadTariffProduct(this.$stateParams.tprId);
                    }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }


            viewSuppliers_Click() {
                this.displaySuppliers(this.productTariff.ProductSupplierList);
            }

            loadTariffPriority() {

                return this.tariffPriorityService.getDropdownList().query({ ownerEntityId: this.productTariff.OwnerEntityId }, () => {


                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadUNDGCode(searchText: string) {
                return this.UNDGCodeService.getDropdownList(searchText).query({ }, () => {             
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadPackingGroup() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.PackingGroup
                }, (resultList) => {
                    this.packingGroupList = resultList;
                }).$promise;
            }


           


            displaySuppliers(productSupplierList: Array<interfaces.master.IProductSupplier> ) {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="productSuppliers">
                                <div class="gts-padding">
                                    <fieldset id="productSuppliers">
                                    <legend>
                                       Product Suppliers
                                    </legend>                                            
                                            <div class="container-fluid row">
                                                            <table class="table table-hover">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Supplier</th>
                                                                                <th>Is Default?</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr ng-repeat="productSupplier in productSupplierCtrl.productSupplierList">                    
                                                                                <td>{{productSupplier.SupplierCode}} - {{productSupplier.SupplierName}}</td>
                                                                                <td><input type="checkbox" ng-model="productSupplier.IsDefault" disabled></td>
                                                                            </tr>
                                                                        </tbody>
                                                            </table>                     
                                             </div>  
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary"  ng-click="productSupplierCtrl.ok()">Ok</button>                                                   
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class productSupplierCtrl {
                        productSupplierList: Array<interfaces.master.IProductSupplier>;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) {
                            this.productSupplierList = productSupplierList;
                        }

                        ok() {
                           
                            this.$uibModalInstance.close();
                        }

                    },
            controllerAs: "productSupplierCtrl",
                    size: "md",
                    resolve: {
                    }
                }).result;
            }

            viewTariffHistory_click() {
                
                if (this.apiTariffDetailList.selection.getSelectedRows().length > 0) {
                    var selectedRow: interfaces.tariff.IProductTariffDetail = this.apiTariffDetailList.selection.getSelectedRows()[0];

                    if (selectedRow && selectedRow.ProductNationalMeasureCountryId > 0) {
                        return this.productTariffService.GetHistory(selectedRow.ProductNationalMeasureCountryId).query((nmcHistoryList: Array<interfaces.tariff.IProductCountryNationalMeasureHistory>) => {

                            this.DisplayNationalMeasureHistory(nmcHistoryList);
                        }
                        , (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    }
                }
            }

            //New way (in line view history)
            viewTariffHistory(ProductNationalMeasureCountryId: number) {
                return this.productTariffService.GetHistory(ProductNationalMeasureCountryId).query((nmcHistoryList: Array<interfaces.tariff.IProductCountryNationalMeasureHistory>) => {
                    this.DisplayNationalMeasureHistory(nmcHistoryList);
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }
            
            DisplayNationalMeasureHistory(nmcHistoryList: Array<interfaces.tariff.IProductCountryNationalMeasureHistory>) {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="nmcHistory">
                                <div class="gts-padding">
                                    <fieldset id="nmcHistory">
                                    <legend>
                                       Product National Measure History
                                    </legend>                                            
                                            <div class="container-fluid row">
                                                            <table class="table table-hover">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Old Status</th>
                                                                                <th>New Status</th>
                                                                                <th>Old Code</th>
                                                                                <th>New Code</th>
                                                                                <th>User</th>
                                                                                <th>Date and Time</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr ng-repeat="nmcHistory in nmcHistoryCtrl.nmcHistoryList">                    
                                                                                <td>{{nmcHistory.OldStatusClassification}}</td>
                                                                                <td>{{nmcHistory.NewStatusClassification}}</td>
                                                                                <td>{{nmcHistory.OldNationalMeasureCode}}</td>
                                                                                <td>{{nmcHistory.NewNationalMeasureCode}}</td>
                                                                                <td>{{nmcHistory.User}}</td>
                                                                                <td>{{nmcHistory.UpdateDate | momentDateFilter: 'YYYY/MM/DD HH:mm:ss'}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                            </table>                     
                                             </div>  
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary"  ng-click="nmcHistoryCtrl.ok()">Ok</button>                                                   
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class nmcHistoryCtrl {
                        nmcHistoryList: Array<interfaces.tariff.IProductCountryNationalMeasureHistory>;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) {
                            this.nmcHistoryList = nmcHistoryList;
                        }
                        ok() {
                            this.$uibModalInstance.close();
                        }
                    },
                    controllerAs: "nmcHistoryCtrl",
                    size: "lg",
                    resolve: {
                    }
                }).result;
            }

            tariffPriorityViewHistory_Click() {
                this.DisplayTariffPriorityHistory(this.productTariff.PriorityHistoryList);
            }

            DisplayTariffPriorityHistory(PriorityHistoryList: Array<interfaces.tariff.ITariffProductPriorityHistory>) {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="productTariffHistory">
                                <div class="gts-padding">
                                    <fieldset id="productTariffHistory">
                                    <legend>
                                       Product Tariff Priority History
                                    </legend>                                            
                                            <div class="container-fluid row">
                                                            <table class="table table-hover">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Old Code</th>
                                                                                <th>Old Description</th>
                                                                                <th>Old Date</th>
                                                                                <th>New Code</th>
                                                                                <th>New Description</th>
                                                                                <th>New Date</th>
                                                                                <th>User</th>
                                                                                <th>Date and Time</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr ng-repeat="priorityHistory in productTariffHistoryCtrl.PriorityHistoryList">                    
                                                                                <td>{{priorityHistory.OldCode}}</td>
                                                                                <td>{{priorityHistory.OldDescription}}</td>
                                                                                <td>{{priorityHistory.OldDate | momentDateFilter: 'YYYY/MM/DD'}}</td>
                                                                                <td>{{priorityHistory.NewCode}}</td>
                                                                                <td>{{priorityHistory.NewDescription}}</td>
                                                                                <td>{{priorityHistory.NewDate | momentDateFilter: 'YYYY/MM/DD'}}</td>
                                                                                <td>{{priorityHistory.User}}</td>
                                                                                <td>{{priorityHistory.UpdateDate | momentDateFilter: 'YYYY/MM/DD HH:mm:ss'}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                            </table>                     
                                             </div>  
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary"  ng-click="productTariffHistoryCtrl.ok()">Ok</button>                                                   
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class productTariffHistoryCtrl {
                        PriorityHistoryList: Array<interfaces.tariff.ITariffProductPriorityHistory>;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) {
                            this.PriorityHistoryList = PriorityHistoryList;
                        }

                        ok() {

                            this.$uibModalInstance.close();
                        }

                    },
                    controllerAs: "productTariffHistoryCtrl",
                    size: "lg",
                    resolve: {
                    }
                }).result;
            }

            tariffCommentsViewHistory_Click() {
                this.DisplayTariffCommentsHistory(this.productTariff.ProductCommentHistoryList);
            }


            DisplayTariffCommentsHistory(ProductCommentHistoryList: Array<interfaces.tariff.ITariffProductCommentHistory>) {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="productCommentHistory">
                                <div class="gts-padding">
                                    <fieldset id="productCommentHistory">
                                    <legend>
                                       Tariff Comment History
                                    </legend>                                            
                                            <div class="container-fluid row">
                                                            <table class="table table-hover">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Old Tariff Comments</th>
                                                                                <th>New Tariff Comments</th>
                                                                                <th>User</th>
                                                                                <th>Date and Time</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr ng-repeat="commentHistory in productCommentHistoryCtrl.ProductCommentHistoryList">                    
                                                                                <td>{{commentHistory.OldTariffComments}}</td>
                                                                                <td>{{commentHistory.NewTariffComments}}</td>
                                                                                <td>{{commentHistory.User}}</td>
                                                                                <td>{{commentHistory.UpdateDate | momentDateFilter: 'YYYY/MM/DD HH:mm:ss'}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                            </table>                     
                                             </div>  
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary"  ng-click="productCommentHistoryCtrl.ok()">Ok</button>                                                   
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class productCommentHistoryCtrl {
                        ProductCommentHistoryList: Array<interfaces.tariff.ITariffProductCommentHistory>;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) {
                            this.ProductCommentHistoryList = ProductCommentHistoryList;
                        }

                        ok() {

                            this.$uibModalInstance.close();
                        }

                    },
                    controllerAs: "productCommentHistoryCtrl",
                    size: "md",
                    resolve: {
                    }
                }).result;
            }

            tariffViewNotesClick() {
                this.DisplayTariffNotes(this.productTariff.ProductHSCodeDisplay.Notes);
            }

            DisplayTariffNotes(Notes: string) {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="tariffNotes">
                                <div class="gts-padding">
                                    <fieldset id="tariffNotes">
                                    <legend>
                                       HS Code Notes
                                    </legend>                                            
                                            <div class="container-fluid row" style="height: 300px; overflow-y: scroll;overflow-x:hidden;">
                                                   <p ng-bind-html="tariffNotesCtrl.Notes"></p>
                                             </div>  
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary"  ng-click="tariffNotesCtrl.ok()">Ok</button>                                                   
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class tariffNotesCtrl {
                        Notes: string;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, $sce) {
                            this.Notes = $sce.trustAsHtml(Notes);
                        }

                        ok() {

                            this.$uibModalInstance.close();
                        }

                    },
                    controllerAs: "tariffNotesCtrl",
                    size: "lg",
                    resolve: {
                    }
                }).result;
            }



            gvwTariffDetail: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: false,
                onRegisterApi: (gridApi) => { this.registerTariffDetailGridApi(gridApi); },
                columnDefs: [
                    {
                        name: 'Country',
                        displayName: 'Country',
                        field: 'CountryCode',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.CountryCode}}</div>`,                     
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 200
                    },
                    {
                        name: 'CODE',
                        displayName: 'Code',
                        field: 'TariffCode',
                        enableSorting: false,
                        enableCellEdit: false,
                        cellTemplate: `<div style="color:#8bac00;text-decoration:underline;" class="ui-grid-cell-contents" ng-click="grid.appScope.productTariffUpdateCtrl.showTariffDescription_Click(row.entity)"> 
                                            {{row.entity.TariffCode}}
                                     </div>`,
                        width: 100
                    },
                    {
                        name: 'DUTYTYPE',
                        displayName: 'Type',
                        field: 'DutyType',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 50
                    },
                {
                        name: "STARTDATE",
                        displayName: "Start Date",
                        field: "StartDate",
                        width: 100,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`
                    },
                    {
                        name: 'ENDDATE',
                        displayName: 'End Date',
                        field: 'EndDate',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-date format="yyyy/MM/dd" ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>`,
                        enableSorting: false,
                        width: 100
                    },
                    {
                        name: 'STATUS',
                        displayName: 'Status',
                        field: 'Status',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.StatusDescription}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ng-change="grid.appScope.productTariffUpdateCtrl.statusChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadStatuses()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 180
                    },
                    {
                        name: 'STATUNIT',
                        displayName: 'Stat. Unit',
                        field: 'StatisticalUoM',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 100
                    },
                    {
                        name: 'TRADEAGREEMENT',
                        displayName: 'Trade Agreement',
                        field: 'TradeAgreement',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 120
                    },
                    {
                        name: 'CRITERIA',
                        displayName: 'Criteria',
                        field: 'OriginCriteria',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 100
                    },
                    {
                        name: 'DUTY',
                        displayName: 'Duty',
                        field: 'DutyFormula',
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 100
                    },
                    {
                        name: 'PR',
                        displayName: 'P&R',
                        field: 'PR',
                        enableSorting: false,
                        enableCellEdit: false,
                        cellTemplate: ` <center>
                                            <div>
                                                    <span ng-if="row.entity.PR === 0" style="color: #bcd856;" class="fa fa-check"></span>
                                                    <span  ng-if="row.entity.PR === 1"class="fa fa-warning" style="color: #f57f20;"></span>
                                                    <span  ng-if="row.entity.PR === 2" class="fa fa-warning" style="color: #cb2330;"></span>
                                            </div>
                                        </center>`,
                        width: 50
                    }, {
                        name: 'VIEW',
                        displayName: 'View',
                        field: '',
                        enableSorting: false,
                        enableCellEdit: false,
                        cellTemplate: `<div>
                                           <gts-tariff-detail   national-measure-code="row.entity.TariffCode" 
                                                                duty-type-code="row.entity.DutyType"
                                                                duty-type-id="row.entity.CodeTypeClassificationValueId"
                                                                country-id="row.entity.CountryId"
                                                                simulation-date="grid.appScope.productTariffUpdateCtrl.simulationDate">
                                          </gts-tariff-detail>
                                    </div>`,
                        width: 50
                    }, {
                        name: 'HISTORY',
                        displayName: 'History',
                        field: '',
                        enableSorting: false,
                        enableCellEdit: false,
                        cellTemplate: `<div>
                                           <div class="GridButton">
                                                <center>
                                                    <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.productTariffUpdateCtrl.viewTariffHistory(row.entity.ProductNationalMeasureCountryId)">
                                                        <span class="fa fa-history"></span>
                                                    </button>
                                                </center>
                                            </div>
                                    </div>`,
                        width: 70
                    },
                ]
            };

            refreshTariff() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.update'
                },
                    () => {
                        return this.productTariffService.GetTariffProductDetailList().query({
                            Id: this.productTariff.Id,
                            showAll: this.showAll
                        }, (result: Array<interfaces.tariff.IProductTariffDetail>) => {
                            this.gvwTariffDetail.data = result;
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            statusChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.tariff.IProductTariffDetail) {
                if (model) {
                    entity.StatusId = model.Id;
                    entity.StatusDescription = model.Display;
                }
            }

            countryChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.tariff.IProductTariffDetail) {
                if (model) {
                    entity.CountryId = model.Id;
                    entity.CountryCode = model.Display;
                }
            }

            showTariffDescription_Click(entity: interfaces.tariff.IProductTariffDetail) {
                this.DisplayTariffDescription(entity.TariffDescription);
            }

            addInternationalTrade_click() {
                this.lookupService.openTariffLookup(undefined, Enum.EnumTariffCodeType.NationMeasure, true, this.productTariff.ProductHSCodeDisplay.HSCode,this.productTariff.OwnerEntityId).result.then(
                    (result: interfaces.tariff.INationalMeasureCodeLookup) => {
                        this.addInternationalTrade(result);
                    }, (errorResult) => {

                    });
            }

            AddTariffCode(tariffCode: interfaces.tariff.ITariffCode): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();
                
                if (this.apiTariffDetailList) {
                    this.productTariff.ProductTariffDetailList = _.map(this.apiTariffDetailList.rowEdit.getDirtyRows(this.apiTariffDetailList.grid), (o) => { return o.entity });
                } else {
                    this.productTariff.ProductTariffDetailList = [];
                }
                
                this.productTariffService.AddTariffCode().save(tariffCode, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    if (this.apiTariffDetailList) {
                        this.apiTariffDetailList.rowEdit.setRowsClean(this.productTariff.ProductTariffDetailList);
                    }

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };
            
            COOChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.productTariff.OriginCountryId = model.Id;
                    this.productTariff.OriginCountry = model;
                    
                    this.Save();
                }
            }
            
            addInternationalTrade(nationalMeasureCodeLookup: interfaces.tariff.INationalMeasureCodeLookup) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.update'
                }, () => {

                    var productTariff = {
                        productId: this.productTariff.Id,
                        countryId: nationalMeasureCodeLookup.CountryId,
                        codeTypeClassificationValueId: nationalMeasureCodeLookup.CodeTypeClassificationValueId,
                        tariffCode: nationalMeasureCodeLookup.NationalMeasureCode,
                        statusId: Enum.ProductTariffStatus.Active,
                        startDate: nationalMeasureCodeLookup.SimulationDate,
                        endDate: undefined  
                    };

                    return this.AddTariffCode(<interfaces.tariff.ITariffCode>productTariff).then((data: boolean) => {
                        if (data) {
                            this.loadTariffProduct(this.$stateParams.tprId);
                            this.$anchorScroll("topAnchor");
                            if (this.$state.current.name === "auth.MasterData.Product.Update.Tariff") {
                                this.$state.go("auth.MasterData.Product.Update.Tariff", { proId: this.productTariff.Id });
                            }
                            else {
                                this.$state.go("auth.ProductTariff.Update", { tprId: this.$stateParams.tprId });
                            }
                        }
                    });
                });
            }

            editNationalMeasureCode(internationalTrade: interfaces.tariff.IProductTariffDetail) {
                this.lookupService.openTariffLookup(internationalTrade.CountryId, Enum.EnumTariffCodeType.NationMeasure, true, internationalTrade.TariffCode).result.then(
                    (nationalMeasureCodeLookup: interfaces.tariff.INationalMeasureCodeLookup) => {

                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'pt.update'
                        }, () => {

                            var productTariff = {
                                productId: this.productTariff.Id,
                                countryId: nationalMeasureCodeLookup.CountryId,
                                codeTypeClassificationValueId: nationalMeasureCodeLookup.CodeTypeClassificationValueId,
                                tariffCode: nationalMeasureCodeLookup.NationalMeasureCode,
                                statusId: Enum.ProductTariffStatus.Active,
                                startDate: internationalTrade.StartDate,
                                endDate: undefined
                            };

                            return this.AddTariffCode(<interfaces.tariff.ITariffCode>productTariff).then((data: boolean) => {
                                if (data) {
                                    this.loadTariffProduct(this.$stateParams.tprId);
                                    this.$anchorScroll("topAnchor");
                                    this.$state.go("auth.ProductTariff.Update", { tprId: this.$stateParams.tprId });
                                }
                            });
                        });


                    }, (errorResult) => {

                    });
            }

            DisplayTariffDescription(TariffDescription: string) {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="tariffNotes">
                                <div class="gts-padding">
                                    <fieldset id="tariffNotes">
                                    <legend>
                                       Tariff Description
                                    </legend>                                            
                                            <div class="container-fluid row">
                                                   <p ng-bind-html="tariffDescriptionCtrl.TariffDescription"></p>
                                             </div>  
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary"  ng-click="tariffDescriptionCtrl.ok()">Ok</button>                                                   
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class tariffDescriptionCtrl {
                        TariffDescription: string;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, $sce) {
                            this.TariffDescription = $sce.trustAsHtml(TariffDescription);
                        }

                        ok() {

                            this.$uibModalInstance.close();
                        }

                    },
                    controllerAs: "tariffDescriptionCtrl",
                    size: "sm",
                    resolve: {
                    }
                }).result;
            }

            registerTariffDetailGridApi(gridApi: uiGrid.IGridApi) {
                this.apiTariffDetailList = gridApi;

                this.apiTariffDetailList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiTariffDetailList.selection.selectRow(gridSelection.row.entity);
                });

                /*this.$timeout(() => {
                    if (this.productTariff) {
                        this.gvwTariffDetail.data = this.productTariff.ProductTariffDetailList;
                    }
                });*/
            } 


            loadCountryList() {
                var defered = this.$q.defer();

                this.countryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });


                return defered.promise;
            }

            loadStatuses() {

                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ProductTariffCountryStatus
                }, (resultList) => {
                    this.statusList = resultList;
                }).$promise;

            }

            loadActiveTariffcountries() {
                return this.tariffCountryService
                  .getActiveTariffCountries()
                  .query({}, (resultList) => {}).$promise;
              }


            gvwUoM: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerUoMApi(gridApi); },               
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "ProductStatisticalUnitId",
                        cellTemplate: `
                        <div class="GridButton">                           
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 35,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.productTariffUpdateCtrl.insertUoM()">
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'SKU',
                        displayName: 'SKU',
                        field: 'SKU',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents"  data-toggle="tooltip" data-placement="top" title="{{row.entity.SKUCode}} - {{row.entity.SKUDescription}}">{{row.entity.SKUCode}} - {{row.entity.SKUDescription}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown  ng-change="grid.appScope.productTariffUpdateCtrl.skuChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadSKUs()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 160
                    },
                    {
                        name: 'Country',
                        displayName: 'Country',
                        field: 'Country',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.CountryCode}} - {{row.entity.CountryDescription}}">{{row.entity.CountryCode}} - {{row.entity.CountryDescription}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ng-change="grid.appScope.productTariffUpdateCtrl.tariffCountryChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadActiveTariffcountries()" ></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 80
                    },                    
                    {
                    name: 'UoM',
                    displayName: 'Customs Unit',
                    field: 'StatUnitOfMeasure',
                    enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.StatUnitCode}} - {{row.entity.StatUnitDescription}}">{{row.entity.StatUnitCode}} - {{row.entity.StatUnitDescription}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown  ng-change="grid.appScope.productTariffUpdateCtrl.statChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadStatUnits(row.entity.CountryId)"></gts-dropdown>
                                </form>`,
                    enableSorting: false,
                    width: 120
                }, {
                    name: 'Qty',
                        field: 'UnitQuantity',
                    displayName: 'Qty',
                    enableCellEditOnFocus: true,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" ignore-wheel name="orderedQty" ng-model="MODEL_COL_FIELD" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000001" min="0" />
                                    </p>
                                </form>`,
                    cellClass: 'text-right',
                    enableSorting: false,
                    width: 65
                    },
                    , {
                        name: "Active",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,                        
                        cellTemplate: `<input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive" ng-change="grid.appScope.productTariffUpdateCtrl.checkBoxStatusUoMChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 55
                    }
                ]
            };

            checkBoxStatusUoMChanged(entity) {
                if (this.apiUoM) {
                    this.apiUoM.rowEdit.setRowsDirty([entity]);
                }
            }


            skuChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.tariff.IUnitsOfMeasure) {
                if (model) {
                    entity.SKUId = model.Id;
                    entity.SKUCode = model.Code;
                    entity.SKUDescription = model.Description;      
                    
                    this.apiUoM.rowEdit.setRowsDirty([entity]);
                }
            }

            tariffCountryChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.tariff.IUnitsOfMeasure) {
                if (model) {
                    entity.StatUnitId =null;
                    entity.StatUnitCode = null;
                    entity.StatUnitDescription = null; 

                    entity.CountryId = model.Id;
                    entity.CountryCode = model.Code;
                    entity.CountryDescription = model.Description;
                    
                    this.apiUoM.rowEdit.setRowsDirty([entity]);
                }
            }            


            statChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.tariff.IUnitsOfMeasure) {
                if (model) {
                    entity.StatUnitId = model.Id;
                    entity.StatUnitCode = model.Code;
                    entity.StatUnitDescription = model.Description; 
                }
            }

            registerUoMApi(gridApi: uiGrid.IGridApi) {
                this.apiUoM = gridApi;
            }

            loadStatUnits(countryId: number) {
                return this.statisticalUnitService.getDropdownList(countryId).query(() => { }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadSKUs() {
                return this.productTariffService.GetSKUDropdownList(this.productTariff.Id).query(() => { }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadCountries() {
                var defered = this.$q.defer();

                this.countryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });


                return defered.promise;
            }

            insertUoM() {
                this.productTariff.ProductUnitOfMeasureList.push(<interfaces.tariff.IUnitsOfMeasure>{
                    ProductStatisticalUnitId: 0,
                    IsActive: true
                });
            }

            removeInlineCG(entity: interfaces.tariff.ICountryGroup) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.update'
                }, () => {
                   
                    return this.productTariffService.DeleteCG(entity.ProductCountryGroupId).save({ Id: entity.ProductCountryGroupId }, (result) => {
                        this.generalService.displayMessageHandler(result);
                        this.$timeout(() => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'pt.update'
                            }, () => {

                                this.loadTariffProduct(this.tprId);
                            });
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    });
                });

                if (this.apiCG) {
                    this.apiCG.rowEdit.setRowsClean(this.productTariff.ProductCountryGroupList);
                }

                if (entity.ProductCountryGroupId === 0) {
                   _.remove(this.gvwCountryGroup.data, (o) => {
                        return o === entity;
                        });
                }
            }

            gvwCountryGroup: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerCountryGroupApi(gridApi); },                
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "ID",
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 65,
                        enableCellEditOnFocus: false,
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.productTariffUpdateCtrl.removeInlineCG(row.entity)" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.productTariffUpdateCtrl.insertCG()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Country',
                        displayName: '* Country Of Declaration',
                        field: 'TariffCountry',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadTariffCountryList()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 160
                    },
                    {
                        name: 'CountryGroup',
                        displayName: '* Country Group',
                        field: 'CountryGroup',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadCountryGroupList(row.entity.TariffCountry.Id)"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 180
                    }
                    ,
                    {
                        name: 'CountryGroupOrigin',
                        displayName: 'Origin Criteria',
                        field: 'OriginCriteria',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadCountryGroupOriginList(row.entity.CountryGroup.Id)"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 155
                    }
                    ,
                    {
                        name: 'PreferenceCode',
                        displayName: 'Preference Code',
                        field: 'PreferenceCode',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadPreferenceCodeList(row.entity.TariffCountry.Id)"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 150
                    }
                    , {
                        name: "Active",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,                        
                        cellTemplate: `<input type="checkbox" ng-disabled="false" ng-change="grid.appScope.productTariffUpdateCtrl.ccValueChanged(row.entity)" ng-model="row.entity.Active">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="false" ng-model="row.entity.Active">
                                                    </p>
                                                </form>`,
                        width: 55
                    }
                ]
            };


            ccValueChanged(entity) {
                if (this.apiCG) {
                    this.apiCG.rowEdit.setRowsDirty([entity]);
                }
            }

            insertCG() {
                this.productTariff.ProductCountryGroupList.push(<interfaces.tariff.ICountryGroup>{
                    ProductCountryGroupId: 0,
                    Active: true
                });
            }

            registerCountryGroupApi(gridApi: uiGrid.IGridApi) {
                this.apiCG = gridApi;

                this.apiCG.edit.on.afterCellEdit(this.$scope, function (rowEntity, colDef, newValue, oldValue) {


                });
            }

            loadTariffCountryList() {
                var defered = this.$q.defer();

                this.tariffCountryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });


                return defered.promise;
            }
           

            loadCountryGroupList(id) {
                
                if (this.productTariff && id) {
                    return this.tariffCountryGroupService.getDropdownList().query({ tariffCountryId: id }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }


            loadPreferenceCodeList(id) {
                if (this.productTariff && id) {
                    return this.tariffPreferenceCodeService.getDropdownList().query({ tariffCountryId: id }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            loadCountryGroupOriginList(id) {
                if (this.productTariff && id) {
                    return this.tariffCountryGroupOriginService.getDropdownList().query({ countryGroupId: id }).$promise;
                } else {
                    var defered = this.$q.defer();
                    defered.resolve([]);
                    return defered.promise;
                }
            }

            registerRestrictionsApi(gridApi: uiGrid.IGridApi) {
                this.apiRestrictions = gridApi;
            }

            insertRestriction() {
                this.productTariff.ProductRestrictionDetailList.push(<interfaces.tariff.IProductRestrictionDetail>{
                    Id: 0
                });
            }

            IsExceptionValueChanged(entity) {
                if (this.apiRestrictions) {
                    this.apiRestrictions.rowEdit.setRowsDirty([entity]);
                }
            }

            loadRestrictions(entity: interfaces.tariff.IProductRestrictionDetail) {
                return this.permitService.getRestrictionsDropdown().query({
                    countryId: entity.Country.Id
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadCheckRestrictionCountries() {
                return this.permitService.getRequiredProductCountryDropdown(this.productTariff.OwnerEntityId).query({
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadFlowList() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.FlowType
                }, () => {
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            showRestrictionDetail(entity: interfaces.tariff.IProductRestrictionDetail) {
                this.$state.go("auth.ProductTariff.Update.RestrictionDetail", { parId: entity.Restriction.Id });
            }

            showComments(entity: interfaces.tariff.IProductRestrictionDetail) {
                this.displayComments(entity.Comments).then((result) => {
                    entity.Comments = result;
                    this.apiRestrictions.rowEdit.setRowsDirty([entity]);
                });
            }

            removeRestriction(entity: interfaces.tariff.IProductRestrictionDetail) {
                this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove the Restriction?").then((result) => {
                   var selectedRow: interfaces.tariff.IProductRestrictionDetail = this.apiRestrictions.selection.getSelectedRows()[0];

                    if (result && entity.Id <= 0) {
                        
                        if (this.apiRestrictions) {
                            this.apiRestrictions.rowEdit.setRowsClean(this.productTariff.ProductRestrictionDetailList);
                        }

                        if (entity.Id === 0) {
                            _.remove(this.gvwRestrictions.data, (o) => {
                                return o === entity;
                            });
                        }
                    }   
                });
            }

            displayComments(Notes: string) {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="conDocNotes">
                                <div class="gts-padding">
                                    <fieldset id="conDocNotes">
                                    <legend>
                                        Restriction Comments
                                    </legend>                                            
                                            <div class="row vertical-align">
                                                <div class="col-md-12">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': docNotes.$invalid}">
                                                         <textarea id="docNotes" class="form-control" ng-model="Notes" rows="25"></textarea>
                                                    </p>
                                                </div>                                               
                                            </div>                                            
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary" ng-disabled="conDocNotes.$invalid" ng-click="ok()">Ok</button>
                                                    <button type="button" class="btn btn-info" ng-click="cancel()">Cancel</button>
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) => {
                        $scope.Notes = Notes;
                       
                        $scope.cancel = () => {
                            $uibModalInstance.dismiss();
                        }

                        $scope.ok = () => {
                            $uibModalInstance.close($scope.Notes);
                        };
                    },
                    controllerAs: "conDocumentNotesCtrl",
                    size: "md",
                    resolve: {
                    }
                }).result;
            }

            //#region Add Restriction
            AddRestriction(){
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    template: `<div class="modal-header"><h3 class="modal-title" id="modal-title">Add Restriction</h3></div>
                                    <div><gts-prohibited-restricted allow-select="true" selected-restriction="addNewCtrl.selectedRestriction"></gts-prohibited-restricted><div>

                                <div class="modal-footer">                               
                                    <button class="btn btn-primary" type="button"  ng-disabled="!addNewCtrl.selectedRestriction" ng-click="addNewCtrl.add()">Add New</button>
                                    <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">Cancel</button>
                                </div>
                               
                    `,
                    controller: class addNewCtrl {
                        selectedRestriction: interfaces.tariff.IProductRestrictionDetail;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private refundSettingService: interfaces.master.IRefundSettingService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            private classificationValueService: interfaces.applicationcore.IClassificationValueService
                        ) {
                        }

                        add() { 
                            this.$uibModalInstance.close(this.selectedRestriction);
                        }

                        cancel() {
                            this.$uibModalInstance.close(null);
                        }
                    },
                    controllerAs: "addNewCtrl",
                    resolve: {
                    }
                }).result.then((result  : interfaces.tariff.IProductRestrictionDetail) => {
                   
                    if (result) {
                        this.productTariff.ProductRestrictionDetailList.push(result); 
 
                        this.$timeout(() => {
                            if (this.apiRestrictions) {
                                this.apiRestrictions.rowEdit.setRowsDirty([result]);                         
                            }
                        });                      
                    }
                });
            }
            //#endregion
            
            //#region Add Manual Restriction
            AddManualRestriction(productTariff:interfaces.tariff.IProductTariff) {
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                        <div class="modal-header">
                            <h3 class="modal-title" id="modal-title">Add manual Restriction</h3>
                        </div>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="restriction.add">
                            <form name="manualRestrictionAdd">
                                <div class="gts-padding">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">
                                                    <req></req>
						                            <label>Country</label>
					                            </div>
					                            <div class="col-md-6">
						                            <gts-dropdown name="country" ng-model="addNewCtrl.selectedCountry" form="inputForm" load-data="addNewCtrl.loadCheckRestrictionCountries()" required="true" ng-change="addNewCtrl.onCountryChange()" ></gts-dropdown>
					                            </div>
				                            </div>
			                            </div>
                                        <div class="row">
				                            <div class="row vertical-align">
					                            <div class="col-md-4">                                                   
                                                    <req></req>
						                            <label>Flow</label>
					                            </div>
					                            <div class="col-md-6">
						                            <gts-dropdown name="country" ng-model="addNewCtrl.selectedFlow" form="inputForm" load-data="addNewCtrl.loadFlowList()" required="true"  ng-disabled="!addNewCtrl.selectedCountry"></gts-dropdown>
					                            </div>
				                            </div>
			                            </div>
                                    
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-primary" type="button"  ng-disabled="!addNewCtrl.selectedCountry || !addNewCtrl.selectedFlow" ng-click="addNewCtrl.add()">Add New</button>
                                        <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div> 
                        `,
                    controller: class addNewCtrl {
                        selectedFlow: interfaces.applicationcore.IDropdownModel;
                        selectedCountry: interfaces.applicationcore.IDropdownModel;                                            
                        messages: interfaces.applicationcore.IMessage[];
                        productRestriction: interfaces.tariff.IProductRestrictionDetail;                      

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private refundSettingService: interfaces.master.IRefundSettingService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                            private prohibitedAndRestrictedService: interfaces.tariff.IProhibitedAndRestrictedService,
                            private permitService: interfaces.master.IPermitService,
                        ) {
                                
                        }

                        cancel() {
                            this.$uibModalInstance.close(null);
                        }

                        add() {

                            var saveItem : interfaces.tariff.IProductRestrictionDetail = <interfaces.tariff.IProductRestrictionDetail>{
                                Id: 0,
                                Country : this.selectedCountry,
                                Flow : this.selectedFlow,
                                IsProhibited: true
                            }


                                this.$uibModalInstance.close(saveItem);

                        }

                        loadCheckRestrictionCountries() {
                            return this.permitService.getRequiredProductCountryDropdown(productTariff.OwnerEntityId).query({
                            }, () => {
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                        }
            
                      
 
                        loadFlowList() {
                              /*  return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                                    classificationId: Enum.ClassificationEnum.FlowType
                                }, () => {
                                
                                    }, (failureData) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                    }).$promise; */

                                    return this.prohibitedAndRestrictedService.getTypesDropdown().query({
                                    }, () => {
                                    }, (failureData) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                    }).$promise;                                    
                        }

                        
                      

                        loadCountries() {
                            return this.countryService.getDropdownList().query({
                            }, () => {
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                        }


                        onCountryChange() {
                           // this.selectedRestriction = null;
                           // this.listRestriction = [];
                        }


                    },
                    controllerAs: "addNewCtrl",
                    resolve: { 
                    }
                }).result.then((result  : interfaces.tariff.IProductRestrictionDetail) => {
                   
                    if (result) {
                        this.productTariff.ProductRestrictionDetailList.push(result); 

                        this.$timeout(() => {
                            if (this.apiRestrictions) {
                                this.apiRestrictions.rowEdit.setRowsDirty([result]);                         
                            }
                        });

                      
                    }
                });
            }
            //#endregion

            CommentsValueChanged(entity) {
                if (this.apiRestrictions) {
                    this.apiRestrictions.rowEdit.setRowsDirty([entity]);
                }
            }

            gvwRestrictions: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerRestrictionsApi(gridApi); },
                columnDefs: [            
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "ID",
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 60,
                        enableCellEditOnFocus: false,
                        cellTemplate: ` <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.productTariffUpdateCtrl.removeRestriction(row.entity)" class="btn btn-warning btn-sm" ng-hide="row.entity.Id > 0">
                                Delete
                            </button>
                        </div>`                       
                    },
                    {
                        name: 'Country',
                        displayName: 'Country',
                        field: 'Country',
                        cellEditableCondition: (scope) => { return scope.row.entity.Id === 0 },
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Code}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadCheckRestrictionCountries()" ></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 80
                    },
                    {
                        name: 'Restriction',
                        displayName: 'Restriction',
                        field: 'Restriction',
                        enableCellEditOnFocus: true,
                        cellEditableCondition: (scope) => { return scope.row.entity.Id === 0 },
                        cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Display}}</div>`,
                        enableSorting: false,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadRestrictions(row.entity)"></gts-dropdown>
                                </form>`,
                        width: 360
                    },
                    {
                        name: 'Flow',
                        displayName: 'Flow',
                        field: 'Flow',
                        enableCellEditOnFocus: true,
                        cellEditableCondition: (scope) => { return scope.row.entity.Id === 0 },
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Description}}</div>`,
                        enableSorting: false,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadFlowList()"></gts-dropdown>
                                </form>`,
                        width: 70
                    },
                    {
                        name: "Prohibited",
                        displayName: "Prohibited",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: (scope) => { return scope.row.entity.Id === 0 },
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.IsProhibited">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="true" ng-model="row.entity.IsProhibited">
                                                    </p>
                                                </form>`,
                        width: 100
                    },
                    {
                        name: "HasPermit",
                        displayName: "Has Permit",
                        enableFiltering: false,
                        enableCellEdit: true,
                        cellEditableCondition: (scope) => { return scope.row.entity.Id === 0 },
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.HasPermit">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="true" ng-model="row.entity.HasPermit">
                                                    </p>
                                                </form>`,
                        width: 100
                    }
                    , {
                        name: "IsException",
                        displayName: "Is Exception",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-disabled="false" ng-change="grid.appScope.productTariffUpdateCtrl.IsExceptionValueChanged(row.entity)" ng-model="row.entity.IsException">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="false" ng-model="row.entity.IsException">
                                                    </p>
                                                </form>`,
                        width: 100
                    },
                    {
                        name: "StartDate",
                        displayName: "Start Date",
                        field: "RestrictionStartDate",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        width: 90
                    },
                    {
                        name: "EndDate",
                        displayName: "End Date",
                        field: "RestrictionEndDate",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        width: 90
                    },
                    {
                        name: 'Comments',
                        displayName: 'Comments',
                        field: 'Comments',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{row.entity.Comments}}</div>`,
                        enableSorting: false,
                        enableCellEdit: true,
                        editableCellTemplate: `
                                   <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-skudesc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                        width: 255
                    },
                    {
                        name: 'CommentsPopUp',
                        displayName: '',
                        field: 'Comments',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<button type="button" ng-click="grid.appScope.productTariffUpdateCtrl.showRestrictionDetail(row.entity)" ng-show = "row.entity.Restriction && row.entity.Id > 0" >                                
                                <span class="fa fa-eye"></span>
                            </button>`,
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 40
                        
                    }
                ]
            };


            registerPermitsApi(gridApi: uiGrid.IGridApi) {
                this.apiPermits = gridApi;
            }

            insertPermit() {
                this.productTariff.ProductPermitDetailList.push(<interfaces.tariff.IProductPermitDetail>{
                    Id: 0, IsActive:true
                });
            }

            loadPermits() {
                return this.productTariffService.GetPermitForProductDropDownList().query({
                    productId: this.productTariff.Id
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

           

            IsPermitActiveValueChanged(entity) {
                if (this.apiPermits) {
                    this.apiPermits.rowEdit.setRowsDirty([entity]);
                }
            }


            showPermitComments(entity: interfaces.tariff.IProductPermitDetail) {

                this.displayPermitComments(entity.Comments).then((result) => {
                    entity.Comments = result;
                    this.apiPermits.rowEdit.setRowsDirty([entity]);
                });

            }



                showAllPermitsChange() {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'pt.update'
                    },
                        () => {
                            return this.productTariffService.GetProductPermits().query({
                                Id: this.productTariff.Id,
                                showAll: this.showAllPermits
                            }, (result: Array<interfaces.tariff.IProductPermitDetail>) => {
                                this.productTariff.ProductPermitDetailList = result;


                                this.$timeout(() => {
                                    this.gvwPermits.data = this.productTariff.ProductPermitDetailList;
                                });

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            

            displayPermitComments(Notes: string) {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="conDocNotes">
                                <div class="gts-padding">
                                    <fieldset id="conDocNotes">
                                    <legend>
                                        Permit Comments
                                    </legend>                                            
                                            <div class="row vertical-align">
                                                <div class="col-md-12">
                                                    <p class="input-group-sm has-feedback" ng-class="{'has-error': docNotes.$invalid}">
                                                         <textarea id="docNotes" class="form-control" ng-model="Notes" rows="25"></textarea>
                                                    </p>
                                                </div>                                               
                                            </div>                                            
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary" ng-disabled="conDocNotes.$invalid" ng-click="ok()">Ok</button>
                                                    <button type="button" class="btn btn-info" ng-click="cancel()">Cancel</button>
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) => {
                        $scope.Notes = Notes;
                       
                        $scope.cancel = () => {
                            $uibModalInstance.dismiss();
                        }

                        $scope.ok = () => {
                            $uibModalInstance.close($scope.Notes);
                        };
                    },
                    controllerAs: "conDocumentNotesCtrl",
                    size: "md",
                    resolve: {

                    }
                }).result;
            }

            removePermit(entity: interfaces.tariff.IProductPermitDetail) {


                this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove the Permit?").then((result) => {
                   var selectedRow: interfaces.tariff.IProductPermitDetail = this.apiPermits.selection.getSelectedRows()[0];

                    if (result && entity.Id <= 0) {
                        
                        if (this.apiPermits) {
                            this.apiPermits.rowEdit.setRowsClean(this.productTariff.ProductPermitDetailList);
                        }

                        if (entity.Id === 0) {
                            _.remove(this.gvwPermits.data, (o) => {
                                return o === entity;
                            });
                        }

                    }   
                });

            }

            gvwPermits: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerPermitsApi(gridApi); },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "ID",
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 60,
                        enableCellEditOnFocus: false,
                        cellTemplate: ` <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.productTariffUpdateCtrl.removePermit(row.entity)" class="btn btn-warning btn-sm" ng-hide="row.entity.Id > 0">
                                Delete
                            </button>
                        </div>`,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.productTariffUpdateCtrl.insertPermit()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Country',
                        displayName: 'Country',
                        field: 'Country',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Code}}</div>`,
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 80
                    },
                    {
                        name: 'Permit',
                        displayName: '*Permit',
                        field: 'Permit',
                        enableCellEditOnFocus: true,
                        cellEditableCondition: (scope) => { return scope.row.entity.Id === 0 },
                        cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Display}}</div>`,
                        enableSorting: false,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadPermits()"></gts-dropdown>
                                </form>`,
                        width: 240
                    },
                    {
                        name: 'Restriction',
                        displayName: 'Restriction',
                        field: 'Restriction',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Display}}</div>`,
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 300
                    },
                    {
                        name: "STARTDATE",
                        displayName: "Start Date",
                        field: "StartDate",
                        width: 100,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`
                    },
                    {
                        name: 'ENDDATE',
                        displayName: 'End Date',
                        field: 'EndDate',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                        enableCellEdit: false,
                        enableSorting: false,
                        width: 100
                    },
                    {
                        name: "Quota",
                        displayName: "Quota",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.ControlQuota">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="true" ng-model="row.entity.ControlQuota">
                                                    </p>
                                                </form>`,
                        width: 70
                    },
                    {
                        name: 'QuotaAmountBalance',
                        displayName: 'Amount Balance',
                        field: 'QuotaBalance',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.QuotaBalance}} {{row.entity.QuotaUoMCode}}</div>`,
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 130
                    },
                    {
                        name: 'QuotaValueBalance',
                        displayName: 'Value Balance',
                        field: 'QuotaValueBalance',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{row.entity.QuotaValueBalance}} {{row.entity.CurrencyCode}}</div>`,
                        enableSorting: false,
                        enableCellEdit: false,
                        width: 130
                    }
                    ,
                    {
                        name: 'Comments',
                        displayName: 'Comments',
                        field: 'Comments',
                        enableCellEditOnFocus: false,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{row.entity.Comments}}</div>`,
                        enableSorting: false,
                        enableCellEdit: true,
                        editableCellTemplate: `
                                   <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-skudesc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                        width: 150
                    }
                    ,                   
                    {
                        name: "Active",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-disabled="false"  ng-change="grid.appScope.productTariffUpdateCtrl.IsPermitActiveValueChanged(row.entity)" ng-model="row.entity.IsActive">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="false" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            };



            gvwProductAttribute: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell" ng-disabled="row.entity.Attribute.IsNotEditable" ui-grid-cell></div>',
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerProductAttributeApi(gridApi); },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.productTariffUpdateCtrl.removeInlinePA(row.entity)" ng-disabled="row.entity.Attribute.IsNotEditable" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 57,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.productTariffUpdateCtrl.insertPA()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Attribute',
                        displayName: '* Attribute',
                        field: 'Attribute',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="row.entity.Attribute.IsNotEditable">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" ng-disabled="row.entity.Attribute.IsNotEditable" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadAttributeList()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 300
                    },
                    {
                        name: 'AttributeValue',
                        displayName: '* Attribute Value',
                        field: 'CustomAttributeAnswerOption',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeNumericValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeDateValue | momentDateFilter: 'YYYY/MM/DD  HH:mm'}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm" >   
                                        <gts-dropdown ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable" ng-change="grid.appScope.productTariffUpdateCtrl.attributeValueChanged(model, row.entity)"  ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}-AttributeValueDD" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadAttributeAnswerOptionList(row.entity.Attribute.Id)"></gts-dropdown>                                                                                                                                               
                                        
                                        <p class="input-group-sm" ng-if="row.entity.Attribute.AttributeType == 58">
                                        <input type="text" ng-change="grid.appScope.productTariffUpdateCtrl.attributeValueOtherChanged( row.entity)" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValueText" ng-model="row.entity.AttributeValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>

                                        <p class="input-group-sm"  ng-if="row.entity.Attribute.AttributeType == 556">
                                        <input type="number" ng-change="grid.appScope.productTariffUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValue" ng-model="row.entity.AttributeNumericValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                        </p>

                                        <gts-date ng-change="grid.appScope.productTariffUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable"   format="yyyy/MM/dd HH:mm"  show-time="true"  ng-model="row.entity.AttributeDateValue" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>
                               `,
                        enableSorting: false,
                        width: 300
                    }
                    , {
                        name: "IsActive",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive" ng-change="grid.appScope.productTariffUpdateCtrl.checkBoxAttributeChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            }; 

            removeInlinePA(entity: interfaces.master.ICustomAttributeValue) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.update'
                }, () => {
                    return this.customAttributeService.deleteCustomAttributeValue(entity.Id).save({ Id: entity.Id }, (result) => {
                        this.generalService.displayMessageHandler(result);
                        this.$timeout(() => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'pt.update'
                            }, () => {
                                this.loadTariffProduct(this.tprId);
                            });
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    });
                });

                if (this.apiPA) {
                    this.apiPA.rowEdit.setRowsClean(this.productTariff.ProductAttributeList);
                }

                if (entity.Id === 0) {
                    _.remove(this.gvwProductAttribute.data, (o) => {
                        return o === entity;
                    });
                    return;
                }
            }
     
            checkBoxAttributeChanged(entity) {
                if (this.apiPA) {
                    this.apiPA.rowEdit.setRowsDirty([entity]);
                    }
                }
           

            attributeValueChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.ICustomAttributeValue) {
                if (model) {
                    entity.AttributeValue = model.Description;

                    if (this.apiPA) {
                        this.apiPA.rowEdit.setRowsDirty([entity]);
                    }
                }                  
            }


            attributeValueOtherChanged(entity: interfaces.master.ICustomAttributeValue) {
                if (entity) {
                    if (this.apiPA) {
                        this.apiPA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            insertPA() {
                this.productTariff.ProductAttributeList.push(<interfaces.master.ICustomAttributeValue>{
                    Id: 0,
                    Attribute: <interfaces.master.ICustomAttribute>{ Id: 0, AttributeType: 0, IsNotEditable: false },
                    CategoryType: 137,
                    CategoryKey: this.productTariff.Id,
                    IsActive: true
                });
            }

            registerProductAttributeApi(gridApi: uiGrid.IGridApi) {
                this.apiPA = gridApi;
            }

            loadAttributeList() {
                return this.customAttributeService.GetCustomAttributeByCategory(137, this.productTariff.OwnerEntityId).query({
                }, (resultList) => {
                    this.attributeList = resultList;
                }).$promise;
            }


            loadAttributeAnswerOptionList(id) {
                return this.customAttributeAnswerOptionService.GetDropDownListByAttribute().query({
                    attributeId: id
                }, (resultList) => {
                    this.attributeAnswerOptionList = resultList;
                }).$promise;
            }

            //Product Additional Info Grid and methods

            registerProductAdditionalInfoApi(gridApi: uiGrid.IGridApi) {
                this.apiProductAdditionalInfo = gridApi;
            }

            removeInlineProdAddInfo(entity: interfaces.tariff.ProductAdditionalInfo) {

                if (entity.Id === 0) {
                    _.remove(this.gvwProductAdditionalInfo.data, (o) => {
                        return o === entity;
                    });
                    return;
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.update'
                }, () => {
                    return this.additionalInfoService.deleteAdditionalInfoValue(entity.Id).save({ Id: entity.Id }, (result) => {
                        this.generalService.displayMessageHandler(result);
                        this.$timeout(() => {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'pt.update'
                            }, () => {
                                this.loadTariffProduct(this.tprId);
                            });
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    });
                });

                if (this.apiProductAdditionalInfo) {
                    this.apiProductAdditionalInfo.rowEdit.setRowsClean(this.productTariff.ProductAdditionalInfoList);
                }         
            }

            insertProdAddInfo(){
                this.productTariff.ProductAdditionalInfoList.push(<interfaces.tariff.ProductAdditionalInfo>{
                    Id: 0,
                    Country: <interfaces.applicationcore.IDropdownModel>{ Id: 0},
                    AdditionalInfo: <interfaces.applicationcore.IDropdownModel>{ Id: 0},
                    IsActive: true
                });
            }

            loadAdditionalInfoList(countryId:number){
                return this.additionalInfoService.getForDropdownWithDescription(countryId).query({
                }, (resultList) => {
                    this.additionalInfoList = resultList;
                }).$promise;
            }

            additionalInfoCountryChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.tariff.ProductAdditionalInfo) {
                if (model) {
                    entity.Country = model;
                    entity.AdditionalInfo = <interfaces.tariff.IAdditionalInfo>{ Id: 0 };
                    entity.AdditionalInfoValue = "";    

                    if (this.apiProductAdditionalInfo) {
                        this.apiProductAdditionalInfo.rowEdit.setRowsDirty([entity]);
                    }
                }                  
            }

            additionalInfoChanged(model: interfaces.tariff.IAdditionalInfo, entity: interfaces.tariff.ProductAdditionalInfo) {
                if (model) {
                    entity.AdditionalInfo = model;         
                    entity.AdditionalInfoValue = "";    

                    if (this.apiProductAdditionalInfo) {
                        this.apiProductAdditionalInfo.rowEdit.setRowsDirty([entity]);
                    }
                }                  
            }  
            
            additionalInfoValueChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.tariff.ProductAdditionalInfo) {
                if (model) {
                    entity.AdditionalInfoValue = model.Description;

                    if (this.apiProductAdditionalInfo) {
                        this.apiProductAdditionalInfo.rowEdit.setRowsDirty([entity]);
                    }
                }                         
            }

            loadAdditionalInfoOptionList(additionalInfoId:number){
                return this.additionalInfoService.getAdditionalInfoAnswerOptionList(additionalInfoId).query({
                }, (resultList) => {
                    this.additionalInfoOptionList = resultList;
                }).$promise;
            }

            additionalInfoTextChanged(entity: interfaces.tariff.ProductAdditionalInfo){
                if (entity) {
                    if (this.apiProductAdditionalInfo) {
                        this.apiProductAdditionalInfo.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            checkBoxAdditionalInfoChanged(entity: interfaces.tariff.ProductAdditionalInfo) {    
                if (entity) {
                if (this.apiProductAdditionalInfo) {
                    this.apiProductAdditionalInfo.rowEdit.setRowsDirty([entity]);
                }   }
            }

            gvwProductAdditionalInfo: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell"  ui-grid-cell></div>',
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerProductAdditionalInfoApi(gridApi); },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.productTariffUpdateCtrl.removeInlineProdAddInfo(row.entity)" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 57,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.productTariffUpdateCtrl.insertProdAddInfo()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Country',
                        displayName: '* Country',
                        field: 'Country',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" ng-change="grid.appScope.productTariffUpdateCtrl.additionalInfoCountryChanged(model, row.entity)"   append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadTariffCountryList()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 300
                    },                    
                    {
                        name: 'AdditionalInfo',
                        displayName: '* Additional Info',
                        field: 'AdditionalInfo',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}"  ng-change="grid.appScope.productTariffUpdateCtrl.additionalInfoChanged(model, row.entity)"  append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadAdditionalInfoList(row.entity.Country.Id)"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 300
                    },
                    {
                        name: 'AdditionalInfoValue',
                        displayName: '* Value',
                        field: 'AdditionalInfoValue',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.AdditionalInfo.ClassificationType == 57"  >{{row.entity.AdditionalInfoValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.AdditionalInfo.ClassificationType == 58"  >{{row.entity.AdditionalInfoValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.AdditionalInfo.ClassificationType == 556" >{{row.entity.AdditionalInfoValue | number:2 }}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.AdditionalInfo.ClassificationType == 557" >{{row.entity.AdditionalInfoValue | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm" >   
                                        <gts-dropdown ng-if="row.entity.AdditionalInfo.ClassificationType == 57"  ng-change="grid.appScope.productTariffUpdateCtrl.additionalInfoValueChanged(model, row.entity)"  ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}-AdditionalInfoValueDD" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.productTariffUpdateCtrl.loadAdditionalInfoOptionList(row.entity.AdditionalInfo.Id)"></gts-dropdown>                                                                                                                                               
                                        
                                        <p class="input-group-sm" ng-if="row.entity.AdditionalInfo.ClassificationType == 58">
                                        <input type="text" ng-change="grid.appScope.productTariffUpdateCtrl.additionalInfoTextChanged(row.entity)" ng-if="row.entity.AdditionalInfo.ClassificationType == 58"  ignore-wheel name="AttributeNumericValueText" ng-model="row.entity.AdditionalInfoValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>

                                        <p class="input-group-sm"  ng-if="row.entity.AdditionalInfo.ClassificationType == 556">
                                        <input type="number" ng-change="grid.appScope.productTariffUpdateCtrl.additionalInfoTextChanged(row.entity)" ng-if="row.entity.AdditionalInfo.ClassificationType== 556"    ignore-wheel name="AttributeNumericValue" ng-model="row.entity.AdditionalInfoValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                        </p>

                                        <gts-date ng-change="grid.appScope.productTariffUpdateCtrl.additionalInfoTextChanged(row.entity)" ng-if="row.entity.AdditionalInfo.ClassificationType == 557"  format="yyyy/MM/dd"ng-model="row.entity.AdditionalInfoValue" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>
                               `,
                        enableSorting: false,
                        width: 300
                    }
                    , {
                        name: "IsActive",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox"  ng-model="row.entity.IsActive" ng-change="grid.appScope.productTariffUpdateCtrl.checkBoxAdditionalInfoChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox"   ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            }; 

            SavePT(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                if (this.apiTariffDetailList) {
                    this.productTariff.ProductTariffDetailList = _.map(this.apiTariffDetailList.rowEdit.getDirtyRows(this.apiTariffDetailList.grid), (o) => { return o.entity });
                } else {
                    this.productTariff.ProductTariffDetailList = [];
                }

                if (this.apiCG) {
                    this.productTariff.ProductCountryGroupList = _.map(this.apiCG.rowEdit.getDirtyRows(this.apiCG.grid), (o) => { return o.entity });
                }
                else {
                    this.productTariff.ProductCountryGroupList = [];
                }

                if (this.apiUoM) {
                    this.productTariff.ProductUnitOfMeasureList = _.map(this.apiUoM.rowEdit.getDirtyRows(this.apiUoM.grid), (o) => { return o.entity });
                }
                else {
                    this.productTariff.ProductUnitOfMeasureList = [];
                }

                if (this.apiPA) {
                    this.productTariff.ProductAttributeList = _.map(this.apiPA.rowEdit.getDirtyRows(this.apiPA.grid), (o) => { return o.entity });
                }
                else {
                    this.productTariff.ProductAttributeList = [];
                }



                if (this.apiRestrictions) {
                    this.productTariff.ProductRestrictionDetailList = _.map(this.apiRestrictions.rowEdit.getDirtyRows(this.apiRestrictions.grid), (o) => { return o.entity });
                }
                else {
                    this.productTariff.ProductRestrictionDetailList = [];
                }

                if (this.apiPermits) {
                    this.productTariff.ProductPermitDetailList = _.map(this.apiPermits.rowEdit.getDirtyRows(this.apiPermits.grid), (o) => { return o.entity });
                }
                else {
                    this.productTariff.ProductPermitDetailList = [];
                }
               
                this.productTariffService.save().save(this.productTariff, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    if (this.apiTariffDetailList) {
                        this.apiTariffDetailList.rowEdit.setRowsClean(this.productTariff.ProductTariffDetailList);
                    }

                    if (this.apiCG) {
                        this.apiCG.rowEdit.setRowsClean(this.productTariff.ProductCountryGroupList);
                    }

                    if (this.apiUoM) {
                        this.apiUoM.rowEdit.setRowsClean(this.productTariff.ProductUnitOfMeasureList);
                    }

                    if (this.apiPA) {
                        this.apiPA.rowEdit.setRowsClean(this.productTariff.ProductAttributeList);
                    }

                    if (this.apiRestrictions) {
                        this.apiRestrictions.rowEdit.setRowsClean(this.productTariff.ProductRestrictionDetailList);
                    }

                    if (this.apiPermits) {
                        this.apiPermits.rowEdit.setRowsClean(this.productTariff.ProductPermitDetailList);
                    }
                                    
                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.update'
                },
                    () => {
                        return this.SavePT().then((data: boolean) => {
                            if (data) {
                                this.loadTariffProduct(this.$stateParams.tprId);
                                this.$anchorScroll("topAnchor");

                                if (this.$state.current.name === "auth.MasterData.Product.Update.Tariff") {
                                    this.$state.go("auth.MasterData.Product.Update.Tariff", { proId: this.productTariff.Id });
                                }
                                else {
                                    this.$state.go("auth.ProductTariff.Update", { tprId: this.$stateParams.tprId });
                                }
                            }
                        });
                    });

            }

            SaveClose() {


                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.update'
                },
                    () => {
                        return this.SavePT().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }

            SaveProduct() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pt.update'
                },
                    () => {
                        return this.SavePT().then((data) => {
                            if (data) {
                                if (this.$state.current.name === "auth.ProductTariff.Update"){
                                    this.$state.go("auth.ProductTariff.Update.Product",{proId:this.productTariff.Id});
                                }
                                else
                                {
                                    this.$state.go("^");
                                }

                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }
        };

        angular.module("app").controller("productTariffUpdateCtrl", controllers.tariff.productTariffUpdateCtrl);
    }
}
